import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import TextView from './views/TextView';
import { SettingsProvider } from './utility/SettingProvider'

function App() {
  const url = `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}${process.env.REACT_APP_TELETYPIST_URL}/listen${window.location.pathname.replace("/listen", "")}`.replace("/listener", "")

  return (
    <>
      <SettingsProvider>
        <TextView url={url} />
      </SettingsProvider>
    </>
  )
}

export default App;
