import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import Settings from './Settings';
import SettingContext from '../utility/SettingProvider'
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { MdKeyboardArrowDown } from 'react-icons/md'
import { toast, ToastContainer } from 'react-toastify';
import parse, { domToReact } from 'html-react-parser'

/* IllumiTypes message packet structure

interface Message {
    containerIndex: number;
    tag: string;
    content: string;
    sc: boolean; //scroll check
    lineBreak: boolean;
    cleanLines: boolean;
    kc?: number; //key code
    ctl?: boolean // control
    ff?: string // Font family
}

*/

let textTrackerCounter = 1

function TextView(props) {
    const websocket = useRef(null)

    const startText = `Du är nu ansluten till tolkningen.<br>
För att komma åt inställningar och chatt klicka var som helst på skärmen.<br>
Ändra storlek genom att trycka på + och -<br>
Ändra utseende genom att trycka på färgpaletten<br>
Öppna chatten genom att trycka på pratbubblan [CTRL+K]<br>
Vi har tystnadsplikt`

    const text = useRef([startText])
    const [textUpdatedTracker, setTextUpdatedTracker] = useState(0)

    const textUpdated = () => {
        setTextUpdatedTracker(textTrackerCounter++)
    }

    const [innerHeight, setInnerHeight] = useState(window.innerHeight)
    const [scrolling, setScrolling] = useState(false)
    const [reconnect, setReconnect] = useState(-1)
    const [reconnectAttempts, setReconnectAttempts] = useState(0)

    const innerDiv = useRef()

    const settings = useContext(SettingContext)

    const handleMessage = (message) => {
        try {
            const data = JSON.parse(message.data)

            if (data.type === "message") {
                console.log("Message", data)
                if (data.message.cleanLines) {
                    text.current.splice(data.message.containerIndex, data.message.cleanLines)
                }

                if (data.message.lineBreak) {
                    text.current.splice(data.message.containerIndex, 0, '')
                }

                text.current[data.message.containerIndex] = data.message.content

                textUpdated()
            } else if (data.type === "clear") {
                text.current = []
                textUpdated()
            } else if (data.type === "currentText") {
                const newText = data.lines.map(line => line.Content)
                const nullValues = [
                    "",
                    "<div></div>",
                    "<div><br></div>",
                    "<div><span><br></span></div>",
                    "<div><span></span></div>"
                ]
                if ((newText.length > 0 && !nullValues.includes(newText[0])) || newText.length > 1) {
                    text.current = newText
                    textUpdated()
                }
            } else if (data.type === "alert") {
                toast[data.variant](data.text)
            }
        } catch (err) {
            console.log("Error", err)
            console.log(message)
        }
    }

    useEffect(() => {
        if (!websocket.current) {
            websocket.current = new WebSocket(props.url + '?name=' + settings.name.current)
        }
        websocket.current.onmessage = handleMessage

        const handleResize = () => {
            setInnerHeight(window.innerHeight)
        }

        const handleWheel = (e) => {
            if (innerDiv.current.scrollTop > 0 && e.deltaY < 0) {
                setScrolling(true)
            }
            if (scrolling) {
                innerDiv.current.scrollTop = innerDiv.current.scrollTop + e.deltaY
            }
        }

        window.addEventListener('resize', handleResize)
        window.addEventListener('wheel', handleWheel)

        if (!scrolling)
            innerDiv.current.scrollTop = innerDiv.current.scrollHeight

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('wheel', handleWheel)
        }
    })



    useEffect(() => {
        websocket.current.onclose = () => {
            if (reconnectAttempts > 10) {
                text.current = ["Klienten tappade anslutningen till servern", "Se till att du använt rätt address och ladda om sidan"]
                textUpdated()
            } else {
                setReconnect(5)
            }
        }
    })

    useEffect(() => {
        if (reconnect > 0) {
            text.current = ["Klienten tappade anslutningen till servern", "Se till att du använt rätt address och ladda om sidan", "Återansluter om... " + reconnect + " sekunder"]
            textUpdated()
            setTimeout(() => setReconnect(reconnect - 1), 1000)
        } else if (reconnect !== -1) {
            websocket.current = new WebSocket(props.url + '?name=' + settings.name.current)
            text.current = [""]
            textUpdated()
            setReconnect(-1)
            setReconnectAttempts(reconnectAttempts + 1)
        }
    }, [reconnect, props.url, settings.name, reconnectAttempts])

    useEffect(() => {
        return () => {
            websocket.current.close()
        }
    }, [])

    const sendChatMsg = msg => {
        websocket.current.send(JSON.stringify({
            type: "message",
            message: msg,
        }))
    }

    const alert = (opened) => {
        websocket.current.send(JSON.stringify({
            type: "alert",
            openedChat: opened,
        }))
    }

    const replace = useMemo(() => {
        const options = {
            replace: ({ attribs, children }) => {
                if (attribs?.class === 'markededit') {
                    return <span style={settings.editStyle.current}>
                        {domToReact(children, options)}
                    </span>
                }
            }
        }
        return options
    }, [settings.editStyle])

    const parsedText = useMemo(() => {
        console.log("Parsing text!", text.current)
        return text.current.map((line, idx) => (
            <div className="maintext noselect" key={idx}>
                <span style={{ backgroundColor: settings.textBackground.current }}>
                    {parse(line, replace)}
                </span>
            </div>
        ))
    }, [settings.textBackground, textUpdatedTracker])

    return (
        <>
            <ToastContainer autoClose={10000} />
            <div style={{
                backgroundColor: settings.backgroundColor.current,
                height: innerHeight,
                width: "100vw",
                position: "absolute",
                paddingTop: settings.topPadding.current,
            }}>
                <div ref={innerDiv} style={{
                    fontFamily: settings.fontFamily.current,
                    color: settings.color.current,
                    fontSize: settings.fontSize.current,
                    backgroundColor: settings.backgroundColor.current,
                    padding: "1%",
                    width: "100vw",
                    height: settings.textHeight.current,
                    maxHeight: innerHeight,
                    overflow: "hidden",
                    position: "absolute"
                }}>
                    {parsedText}
                </div>
            </div>
            <div style={{ position: "absolute", bottom: "1vh", right: "1vw" }}>
                {scrolling && <Button className="mr-2" onClick={e => {
                    setScrolling(false)
                    e.preventDefault()
                    e.stopPropagation()
                }}><MdKeyboardArrowDown size={44} /></Button>}
                <Settings sendChatMsg={sendChatMsg} alert={alert} />
            </div>
        </>
    )
}

function ChooseName(props) {
    const settings = useContext(SettingContext)
    const [newName, setNewName] = useState('')

    return (
        <Container>
            <Row style={{ height: "100%" }} className="justify-content-center align-content-center">
                <Col style={{ color: "white" }} md={5}>
                    <Form onSubmit={e => {
                        e.preventDefault()
                        if (newName.length > 0)
                            settings.name.set(newName)
                    }}>
                        <Form.Group>
                            <Form.Label>Ditt namn</Form.Label>
                            <Form.Control name="ssnamn" id="ssnamn" autoComplete="off" onChange={e => setNewName(e.target.value)} type="text" />
                        </Form.Group>
                        <Button variant="primary" type="submit">Ok</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default function TextViewOrName(props) {
    const settings = useContext(SettingContext)

    return settings.name.current ? <TextView url={props.url} /> : <ChooseName />
}